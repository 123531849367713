import React from "react";

interface PostDetailPageProps {
  id: string;
}

const PostDetailPage: React.FC<PostDetailPageProps> = ({id}) => {
  return (
    <div>
      <h1>Post Detail {id}</h1>
      <p>This is the detail page for post {id}.</p>
    </div>
  )
}

export default PostDetailPage;