import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage.tsx";
import PostDetailPage from "./pages/PostDetailPage/PostDetailPage.tsx";

const App = () => {
  return (
    <Router>
      <div className='container mx-auto p-4'>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/post/:id" element={<PostDetailPage id={""}/>}/>
        </Routes>
      </div>
    </Router>
  )
}

export default App;