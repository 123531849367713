const HomePage = () => {
  return (
    <div>
      <h1>Welcome to My Blog</h1>
      <ul>
        <li><a href='/post/1'>Post 1 Title</a></li>
        <li><a href='/post/2'>Post 2 Title</a></li>
      </ul>
    </div>
  )
}

export default HomePage